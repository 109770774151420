.edit_btn {
  position: absolute;
  bottom: 0;
}

.purple_background {
  border-radius: 5px;
}

.accordian {
  margin: 10px 0 0 0;
}

.padding_bottom_7 {
  padding-bottom: 7px !important;
}

.accordian_row {
  margin: 14px 0;
  padding: 0 15px;
}

.top_align {
  margin-top: 10px;
}

.top_align_edit {
  margin-top: 5px;
}

.enableField {
  transform: translate(11px, 0px);
}

.accordian_header_title {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
}

.accordianSummaryRoot {
  min-width: 60px;
}

.top_margin_20 {
  margin-top: 20px;
}

.secondaryButton {
  margin-right: 6px;
}

.border_accordian {
  border-radius: 14.9px;
  border-bottom: none;
  border-left: 1.5px solid #e7e6e4;
  border-right: 1.5px solid #e7e6e4;
  border-top: 1.5px solid #e7e6e4;
  padding-top: 6px;
}

.no_border {
  border: 0;
}

.top_margin_5 {
  margin-top: 5px;
  margin-left: 5px;
}

.top_margin_10 {
  margin-top: 10px;
}

.flex_display {
  display: flex;
}

.left_padding_10 {
  padding-left: 10px;
  margin-bottom: 8px;
}

.accordian_header_sub_title {
  color: #272522;
  font-weight: 400;
  font-size: 14px;
  line-height: 142%;
  margin-top: 2px;
}

.accordian_description_title {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 10px;
}

.accordian_description_sub_title {
  color: #616161;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.7px;
}

.attachment_icon {
  margin-left: 20px;
  padding: 1px;
}

.border_radius_5 {
  border-radius: 5px;
}

.padding_16 {
  padding: 12px;
}

.attachment_icon:hover {
  background-color: #efefef;
  border-radius: 2px;
}

.attachment_row {
  position: relative;
  width: 100%;
  justify-content: space-between;
}

.attachment_name {
  margin-right: 10px;
  max-width: 300px;
  overflow: hidden;
  font-size: 14px;
  text-overflow: ellipsis;
  word-break: break-word;
}

.save_btn {
  position: absolute;
  right: 10px;
}

.bottom_margin {
  margin-bottom: 12px;
}

.group_email_container {
  border: 1px solid black;
  padding: 10px;
  max-height: 300px;
  overflow-y: scroll;
}

.group_email {
  padding: 5px;
  justify-content: space-between;
}

.disclaimer_text {
  font-size: 17px;
}

.mail_svg_container {
  margin: auto;
  margin-top: 50px;
}

.mail_svg {
  font-size: 80px !important;
}

.close_svg {
  font-size: 16px !important;
  cursor: pointer;
  fill: black !important;
  position: absolute;
  top: -5px;
  right: -7px;
  background-color: transparent;
}

.to_container {
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  width: 100%;
  padding: 8px 3px;
}

.to_container_focus {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.to_input_field {
  border: none;
  width: 100%;
}

.input_field {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid grey;
}

.hint {
  font-size: 14px;
  color: #666666;
  width: 100%;
  line-height: 1.29;
  letter-spacing: normal;
  margin-top: 5px;
}

.recipients_container {
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
  border-radius: 5px;
  /* padding: 10px; */
  border: 1px solid grey;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.groups_container {
  color: black;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
}

.group {
  color: #6c5dd3;
  border: 1px solid #6c5dd3;
  padding: 5px 10px;
  border-radius: 20px;
  background-color: white;
  font-size: 16px;
  justify-content: right;
  white-space: nowrap;
  margin: 15px 10px 0 0px;
  cursor: pointer;
  overflow: hidden;
}

.invalid_email {
  background-color: #ff222220 !important;
  color: maroon !important;
}

.selected_email {
  border: 1px solid #000;
  padding: 3px 7px;
  border-radius: 20px;
  background-color: white;
  font-size: 12px;
  white-space: nowrap;
  margin: 5px 10px 0 0px;
  cursor: pointer;
  position: relative;
}

.optionBar {
  position: absolute;
  right: 20px;
  z-index: 10;
  top: 10px;
}

.primaryText {
  color: #6c5dd3 !important;
}

.primaryTextOption {
  color: #493ab1;
  font-weight: 500;
  cursor: pointer;
}

.textYellow {
  color: #ee7200;
}

.cursorPointer {
  cursor: pointer;
}

.modal_close {
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
}

.selected_group {
  color: #6c5dd3;
  border: 1px solid transparent;
  padding: 3px 7px;
  border-radius: 6px;
  background-color: #f4f0ff;
  font-size: 12px;
  white-space: nowrap;
  margin: 5px 10px 0 0px;
  cursor: pointer;
  position: relative;
}

.groups {
  margin: 0 15px 0 15px;
  overflow-wrap: break-word;
  max-width: 100%;
  max-height: 160px;
  overflow: auto;
}

.search_string {
  /* border: 1px solid black; */
  padding: 5px;
}

.search_string:hover {
  background-color: #efefef;
}

.search_container {
  max-height: 180px;
  overflow: auto;
  padding: 10px;
}

.drafts_container {
  border: 1px solid #efefef;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* max-height: 210px; */
  /* overflow: hidden; */
  position: relative;
}

.compose_container {
  /* border: 1px solid #efefef; */
  /* border-radius: 15px; */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
  /* padding: 5px 10px; */
}

.show_more {
  position: absolute;
  bottom: -13px;
  margin: auto;
  padding: 3px 5px;
  font-size: 12px;
  left: 45%;
}

.subject {
  font-weight: 800;
}

.draft_subject {
  color: #6c5dd3;
}

.draft_time {
  color: #666666;
}

.draft_container {
  border-top: 1px solid #e0e0e0;
  padding: 5px 10px !important;
  cursor: pointer;
}

.drafts_header {
  padding: 5px 10px !important;
  background-color: rgba(0, 0, 0, 0.1);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.email_container {
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.email_padding {
  padding: 5px 10px 0 10px !important;
}

.email_view_cta {
  color: #6c5dd3;
  text-decoration: underline;
  cursor: pointer;
}

.email_action_container {
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  width: 100%;
  padding: 10px;
  justify-content: space-between;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.action_text {
  color: #666666;
  font-weight: 600;
}

.compose_cta {
  font-size: 18px;
}

.arrowIcon {
  transform: translate(-3px, -1px) scale(0.7);
}

.bottom_margin {
  margin-bottom: 12px;
}

.disclaimer_text {
  font-size: 17px;
}
.searchIcon {
  display: block;
  margin-right: -10px;
}
.searchIcon svg {
  width: 50px;
}

.email_body {
  font-size: 16px;
}

.send_cta {
  white-space: nowrap;
  border-radius: 6px !important;
  border: 1px solid #6c5dd3;
}

.send_cta[disabled] {
  opacity: 0.5;
  cursor: auto;
}

.btn_danger {
  white-space: nowrap;
  border-radius: 6px !important;
  background-color: #d61a1a;
  border: solid 1px #d61a1a;
  color: #fff;
}

.send_cta_outline {
  background-color: #fff;
  border: solid 1px #6c5dd3;
  color: #6c5dd3;
  border-radius: 6px;
}

.breadcrumbstyle {
  margin: 10px 0;
}

.elementOptionsHolder {
  border: 1px dashed #8167cc;
  color: #8167cc;
  text-align: center;
}

.elementOptionsDivider {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 30px;
}

.elementOptionsDivider:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  z-index: -1;
  border-top: dashed 1px #8167cc;
}

.emailTemplateButtonsHolder {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
}

.emailTemplateButtonsHolder .templateButton {
  background-color: rgba(244, 240, 255, 1);
  padding: 6px;
  border-radius: 6px;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: #8167cc;
  height: 100px;
  font-weight: 500;
  justify-content: center;
  vertical-align: center;
  cursor: pointer;
  font-size: 14px;
}

.emailTemplateButtonsHolder .templateButton img {
  width: 36px;
  margin: 0 auto 12px auto;
}

.modal {
  background-color: #fff;
  border-radius: 6px;
  max-height: 90%;
  max-width: 800px;
  padding: 20px;
  width: 100%;
  height: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.modalSm {
  background-color: #fff;
  border-radius: 6px;
  max-height: 90%;
  max-width: 550px;
  padding: 20px;
  width: 100%;
  height: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
}

.modalRight {
  background-color: #fff;
  border-radius: 0px;

  max-width: 800px;
  padding: 20px;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.tabs {
  margin: 10px 0;
}

.tabs ul {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #e4e8f0;
}

.tabls ul li {
  display: inline-block;
}

.tabs ul li a {
  padding: 10px 18px;
  display: block;
  position: relative;
  color: #787878;
  font-weight: 500;
  text-decoration: none;
}

.tabs ul li a.active {
  color: #313b4f;
}

.tabs ul li a.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 10%;
  right: 10%;
  height: 4px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #6c5dd3;
}

.emailRow {
  border: solid 1px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 12px;
}

.emailRow .header {
  display: flex;
  padding: 8px;
  justify-content: space-between;
  border-bottom: solid 1px rgba(0, 0, 0, 0.15);
}

.emailRow .footer {
  padding: 8px;
}

.emailRow .footer .statsHolder {
  display: flex;
  align-items: center;
}

.emailRow .footer .statsHolder div:first-child {
  margin-right: 28px;
}

.emailRow .footer .statsHolder .stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 28px;
}

.buttonFaded {
  padding: 6px 12px;
  border-radius: 6px;
  background-color: #f4f0ff;
  color: #8167ce;
  border: none;
  font-weight: 500;
}
.dividerText {
  position: relative;
}
.dividerText:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  z-index: -1;
  border-top: solid 1px #ededed;
}

.bottomBar {
  background-color: #fff;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
}

.fontBold {
  font-weight: 600;
}

.textPrimary {
  color: #6c5dd3;
}

.textRight {
  text-align: right;
}

.textCenter {
  text-align: center;
}

.textDanger {
  color: #cb0404;
}

.inputGhost {
  border: none;
  width: 100% !important;
}

.emailBlock {
  border: dashed 2px #8167ce;
  border-radius: 6px;
  padding: 8px;
}

.emailBlock .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  border-bottom: solid 1px #8167ce60;
  font-weight: 500;
  font-size: 20px;
}

.emailBlock .body {
  padding: 20px 6px;
}

.test_cta {
  font-size: 12px;
  margin: 10px 0 0 0;
}

.divider {
  padding-left: 16px;
  padding-right: 16px;
}

.divider:last-child {
  display: none;
}

@media screen and (max-width: 780px) {
  .send_cta_outline {
    padding: 4px 10px;
  }

  .optionBar {
    position: relative;
    margin: 0 !important;
    justify-content: flex-end;
    right: 0;
    padding-bottom: 10px;
  }

  .view_title {
    font-size: 18px;
  }
  .mobileParent form {
    padding-left: 15px !important;
  }
  .bookedSessionMobile {
    width: 42vw;
    min-width: 100px !important;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  .bookedInputMobile > div {
    background-color: #ffff !important;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.1);
  }
  .bookedInputMobile > div::before {
    border-bottom: none !important;
  }
  .bookedInputMobile > div::after {
    border-bottom: none !important;
  }
  .bookedSessionMobile:after {
    border-bottom: unset !important;
  }
  .listItem {
    width: 100vw !important;
    padding: 0 !important;
  }
  .filterMobile {
    margin-top: 10px !important;
    margin-right: -5px !important;
    margin-bottom: 35px !important;
    padding-left: 15px;
  }

  .bookedSessionMobile > div::before {
    border-bottom: none !important;
  }
  .bookedSessionMobile > div::after {
    border-bottom: none !important;
  }

  .bookedSessionMobile input {
    background-color: #ffff !important;
  }
  .bookedSessionMobile > div > div {
    background-color: #ffff !important;
  }
  .clearFilterMobile {
    padding-left: 15px;
  }
  .card {
    margin: 0 15px;
  }
  .listItem > div:nth-child(2) > div {
    border: none;
  }

  .compose_cta {
    font-size: 12px;
    padding: 5px 7px;
    white-space: nowrap;
  }

  .subject {
    font-size: 12px;
  }

  .draft_subject {
    font-size: 12px;
  }

  .draft_time {
    font-size: 12px;
  }

  .email_action_container {
    font-size: 12px;
  }

  .email_view_cta {
    font-size: 12px;
  }

  .email_body {
    font-size: 12px;
  }

  .duplicate_cta {
    padding: 5px 7px;
    font-size: 10px;
  }

  .compose_container {
    margin-top: 20px;
  }

  .to_input_field {
    font-size: 12px;
  }

  .input_field {
    font-size: 12px;
  }

  .groups_header {
    font-size: 12px;
  }

  .group {
    font-size: 12px;
    padding: 5px 7px;
    white-space: normal;
  }

  .groups {
    max-height: 140px;
  }

  .hint {
    font-size: 10px;
  }

  .test_info {
    margin: 0;
  }

  .edit_btn {
    margin-top: 10px;
    position: relative;
  }

  .accordian {
    padding: 0 15px;
  }

  .test_cta {
    width: 100%;
  }

  .accordian_header_sub_title {
    font-size: 13px;
  }
}

@media screen and (max-width: 600px) {
  .emailRow .footer .statsHolder {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex: 1;
    margin-bottom: 12px;
  }

  .emailRow .footer .statsHolder .stats {
    flex: 1;
    margin-right: 0px;
  }

  .emailRow .footer .statsHolder .fontBold {
    font-size: 12px;
    text-align: center;
  }

  .buttonFaded {
    flex: 1;
  }

  td {
    min-width: 0px;
  }

  .accordian {
    padding: 0 15px;
  }

  .accordian_mobile_cta {
    width: 100%;
    margin: 10px 0 0 0;
    text-align: center;
  }

  .purple_background {
    background-color: #c0bae880;
    padding: 15px;
  }
}
