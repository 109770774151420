.modalFooter {
  display: flex;
  float: right;
  padding: 18.5px 6px 18.5px 10px;
}

.modalFooterParent {
  border-top: 2px solid rgb(231, 230, 228);
  padding-left: 16px;
  padding-right: 16px;
}

.modalLabel {
  font-size: 14px;
  list-style: 16.7px;
  font-weight: 400;
  white-space: pre-line;
  color: rgba(0, 0, 0, 0.85);
}

.padding_adjust_footer {
}

.modalHeader {
  display: block;
}

.desktop_modal_paper {
  max-width: 854px !important;
  width: 100%;
}

.margin_right_23 {
  margin-right: -4px;
  transform: translate(30px, 0px);
}

.modalHeader p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.editButton {
  margin-left: 34px;
}

.modalHeader div {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 5px;
}

.backgroundContainer {
  /* padding-right: 15px; */
  height: 33px;
  padding: 0;
  margin-right: -1px;
  padding-top: 5px;
  border-radius: 0px 0px 6px 6px;
}

.secondaryButton {
  margin-right: 10px;
}

.tooltip {
  margin-right: 6px;
  margin-top: 10px;
  color: #493ab1;
}

.margin_bottom_20px {
  padding: 0px 0px 12px 12px;
}

.editIcon {
  padding-right: 9px;
  display: inline;
}

@media screen and (max-width: 780px) {
  .modalFooter {
    display: flex;
    border-top: 2px solid rgb(231, 230, 228);

    float: right;
    height: 320px;
    padding: 15px 15px 55px 5px;
    position: fixed;
    height: 50px;
    background-color: white;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-bottom: 0px;
  }

  .mainButtonWidth {
    width: 92vw;
  }

  .secButtonWidth {
    width: 90vw;
  }

  .margin_bottom_20px {
    margin-bottom: 100%;
    padding: 0;
  }

  .editButton {
    margin-left: 10px;
  }
}
